import React, {useEffect, useRef, useState} from "react";
import {Dialog, DialogContent} from "@mui/material";
import {
    useSearchResultDetailsState,
    useSearchResultDetailsTranslation,
} from "../search-result-details/SearchResultDetailsContext";
import {useSearchState} from "../search/SearchContext";
import Grid from "@mui/material/Grid";
import {ObjectViewCloseButton} from "./ObjectViewCloseButton";
import {ObjectViewMetaSectionWrapper} from "./ObjectViewMetaSectionWrapper";
import {ObjectViewMediaSectionWrapper} from "./ObjectViewMediaSectionWrapper";
import {useObjectViewPreviousNext} from "./useObjectViewPreviousNext";
import {useObjectViewHandlers} from "./useObjectViewHandlers";

/**
 * Component that holds the object view dialog.
 * @param open  bool  Sets the openstate for the dialog.
 * @returns {JSX.Element}
 * @constructor
 */
export const ObjectView = ({open}) => {
    const gridRef = useRef();
    const dialogRef = useRef();
    const t = useSearchResultDetailsTranslation();
    const {results} = useSearchState();
    const {model, fullscreen, editMode} = useSearchResultDetailsState();
    
    // The number of columns to use in "preview mode", altered to 8/5 when in editing mode.
    const [columnDefs, setColumnDefs] = useState([9, 3]);

    // Margins surrounding the dialog when opened in fullscreen vs. "as a dialog".
    const fullscreenMargin = fullscreen ? 0 : "6vw";

    const [
        openDialogCallback,
        handleOnClose,
        handleMediaPlayClick,
        openDialog
    ] = useObjectViewHandlers({dialogOpen: open});

    const [
        getPreviousButton,
        getNextButton,
        setShowNavigation,
        setPdfOpen,
    ] = useObjectViewPreviousNext({
        model: model,
        results: results,
        smallScreen: false,
        openDialogCallback: openDialogCallback,
    });

    const pdfOpenedCallback = open => {
        setPdfOpen(open);
    };

    const toggleEditModeOn = () => {
        setShowNavigation(false);
        setColumnDefs([7, 5]);
    };

    const toggleEditModeOff = () => {
        setColumnDefs([9, 3]);
        setShowNavigation(true);
    };

    /**
     * Hook used to toggle between edit/preview mode.
     */
    useEffect(() => {
        if (!editMode) {
            toggleEditModeOff();
        } else if (editMode) {
            toggleEditModeOn();
        }
    }, [editMode]);

    return (
        <>
            {getPreviousButton()}
            {getNextButton()}
            <Dialog
                open={openDialog}
                onClose={handleOnClose}
                fullScreen={true}
                hideBackdrop={true}
                sx={{
                    margin: fullscreenMargin,
                }}
                PaperProps={{
                    style: {borderRadius: 5, backgroundColor: "#bbb"},
                }}
                ref={dialogRef}
            >
                <DialogContent
                    sx={{
                        overflow: "hidden",
                        padding: 0,
                    }}
                >
                    <ObjectViewCloseButton
                        editMode={editMode}
                        closeDialogCallback={handleOnClose}
                    />
                    <Grid
                        container
                        direction={"row"}
                        sx={{
                            minWidth: "95%",
                            minHeight: "100%",
                        }}
                        ref={gridRef}
                    >
                        <ObjectViewMediaSectionWrapper
                            t={t}
                            gridRef={gridRef}
                            columnDefs={columnDefs}
                            smallScreen={false}
                            mediaPlayCallback={handleMediaPlayClick}
                            pdfOpenedCallback={pdfOpenedCallback}
                        />
                        <ObjectViewMetaSectionWrapper
                            t={t}
                            smallScreen={false}
                            columnDefs={columnDefs}
                            dialogRef={dialogRef}
                        />
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};
