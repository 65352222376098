import React, {useEffect} from "react";
import {useFolderSearch} from "./useFolderSearch";
import {FoldersTable} from "./FoldersTable";
import {SET_SEARCHING, useFoldersDispatch, useFoldersState} from "./FoldersContext";
import {ResultTableProvider} from "../search/ResultTableContext";
import {FolderSearchSettings} from "../search/FolderSearchSettings";

export const MyFolders = () => {
    const folderSearchSettings = FolderSearchSettings('dams.folderListSearchResults.config');

    const foldersDispatch = useFoldersDispatch();

    const {query, sort, start, fq} = useFoldersState();

    useFolderSearch({
        query: query,
        sort: sort,
        start: start,
        rows: folderSearchSettings.getNumberOfRows(),
        documentType: "Folder",
        folderType: "(!ProjectArcheology !ProjectGeneral)",
        fq: fq,
        onlyMine: true,
    });

    useEffect(() => {
        foldersDispatch({
            type: SET_SEARCHING
        });
    }, [foldersDispatch]);

    return <ResultTableProvider><FoldersTable/></ResultTableProvider>;
};
