import React from "react";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import Box from "@mui/material/Box";

/**
 * Renders a selectable folder item component.
 *
 * @param {Object} props - The props object.
 * @param {Object} props.folder - The folder object.
 * @param {boolean} props.selected - Whether the folder is selected.
 * @param {function} props.onSelect - The function to be called when the folder is selected.
 * @return {JSX.Element} The selectable folder item component.
 */
export const SelectableFolderItem = ({folder, selected, onSelect}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                justifyItems: 'center',
                maxWidth: 100,
                cursor: 'pointer',
                '&:hover': {
                    color: '#00695f',
                    backgroundColor: '#e8e8e8'
                },
                backgroundColor: () => {
                    return selected ? '#e8e8e8' : 'white';
                },
            }}
            onClick={onSelect}
        >
            <FolderIcon
                sx={{
                    fontSize: 100,
                    color: "GrayText",
                    "&:hover": {
                        color: "#00695f",
                    },
                }}
            />
            <Typography noWrap variant={"caption"} sx={{
                textOverflow: 'ellipsis',
                maxWidth: 100
            }}>
                {folder.title}
            </Typography>
        </Box>
    );
};
