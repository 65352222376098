import {useEffect, useState} from "react";

/**
 * Custom hook to extract the available tag facet-values from the current result set.
 * @param {boolean} loading - Whether the component is still loading.
 * @param {string} searchValue - The search value/argument being used.
 * @param {Array} selected - The selected items.
 * @param {Object} facets - The facets object.
 * @returns {Array} - The options array.
 */
export const useOptions = (loading, searchValue, selected, facets) => {
    const [options, setOptions] = useState({});

    /**
     * Method used to get the list of options to display in an autocomplete component.
     * @param data  Array   An array containing facet values.
     * @param searchValue   str The search value/argument being used.
     * @param selected  Array   The selected items.
     * @returns {{[p: string]: any}}
     */
    const getOptions = (data, searchValue, selected) => {
        const matchingKeys = (searchValue !== '' && searchValue.length >= 2)
            ? Object.keys(data)
                .filter(k => k.toLowerCase().indexOf(searchValue?.toLowerCase()) > -1)
            : Object.keys(data);

        // Get the data that matches the search criteria.
        const filtered = Object.fromEntries(matchingKeys.map(k => [k, data[k]]));

        // Add selected/checked filter values to the list.
        const selectedKeys = selected.map(s => (s.value));
        const selectedValues = Object.fromEntries(selectedKeys.map(k => [k, data[k]]));

        return {...filtered, ...selectedValues};
    };

    useEffect(() => {
        if (loading || !facets || Object.keys(facets).length === 0) {
            return;
        }
        const opts = getOptions(facets, searchValue, selected);
        setOptions(opts);
    }, [loading, searchValue, facets, selected]);

    return options;
};