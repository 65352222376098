import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import React from "react";

/**
 * Component used to display the title above the list of facets in a search filter.
 * Containing both the title of the filter, and the search-input field.
 * @param title
 * @param label
 * @param value
 * @param onChangeCallback
 * @param setSearchValueCallback
 * @returns {JSX.Element}
 * @constructor
 */
export const AccordionFilterTitle = ({ title, label, value, onChangeCallback, setSearchValueCallback }) => {
    return <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'baseline',
        justifyContent: 'space-between',
    }}>
        <Box flexGrow={1}>{title}</Box>
        <TextField
            id="creators-search"
            sx={{ flexShrink: 1, flexBasis: '120px' }}
            size="small"
            label={label}
            variant="standard"
            onChange={onChangeCallback}
            value={value}
            InputProps={{
                endAdornment: value && (
                    <InputAdornment position="end" size="small">
                        <IconButton size="small" onClick={() => {
                            setSearchValueCallback()
                        }}>
                            <ClearIcon id="creators-clear" />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    </Box>;
}