import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {UploadedFilesToolbar} from "../../files/UploadedFilesToolbar";
import {ViewTypeOption} from "../../files/ViewTypeOption";
import {DamsForm} from "../../form/DamsForm";
import {FormMetadata} from "../FormMetadata";
import {UploadedFilesGrid} from "../../files/UploadedFilesGrid";
import {UploadedFilesTable} from "../../files/UploadedFilesTable";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import InfoIcon from "@mui/icons-material/Info";
import {useDocumentState} from "../../documents/documentContext";
import {FormikSubmitButton} from "../../form/FormikSubmitButton";
import {useNewFilesTranslation} from "../../files/NewFilesTranslationContext";
import Typography from "@mui/material/Typography";
import {AddMetadataInfoText} from "./AddMetadataInfoText";
import {NumberEditedInfo} from "./NumberEditedInfo";
import {getNumMissingTitle} from "./metadatagridFunctions";
import {BatchEditProvider} from "../../documents/batchEditContext";


/**
 * Renders a metadata grid component with various functionalities for managing uploaded files and their metadata.
 *
 * @param {Object} props - The component props.
 * @param {React.RefObject} props.toolbarRef - A reference to the toolbar element.
 * @param {string} props.viewType - The current view type.
 * @param {Function} props.setViewTypeCallback - A callback function to set the view type.
 * @param {Function} props.clearAllCallback - A callback function to clear all selections.
 * @param {Function} props.checkAllCallback - A callback function to check all files.
 * @param {Array} props.checked - An array of checked files.
 * @param {Function} props.checkFileCallback - A callback function to check a file.
 * @param {Function} props.saveDocumentsCallback - A callback function to save the documents.
 * @param {Object} props.validationSchema - The validation schema for the form.
 * @param {Function} props.numberOfCheckedCallback - A callback function to get the number of checked files.
 * @return {JSX.Element} The rendered metadata grid component.
 */
export const MetadataGrid = ({
                                 toolbarRef,
                                 viewType,
                                 setViewTypeCallback,
                                 clearAllCallback,
                                 checkAllCallback,
                                 checked,
                                 checkFileCallback,
                                 saveDocumentsCallback,
                                 validationSchema,
                                 numberOfCheckedCallback,
                             }) => {
    const t = useNewFilesTranslation();
    const {formData, files, saved} = useDocumentState();
    const [numMissingTitle, setNumMissingTitle] = useState(files.length);
    const [showAddMetadataInfo, setShowAddMetadataInfo] = useState(false);


    const getViewBySelectedType = () => {
        if ("gridlist" === viewType) {
            return (
                <UploadedFilesGrid
                    checked={checked}
                    checkFile={checkFileCallback}
                    smallscreen={false}
                />
            );
        } else {
            return (
                <UploadedFilesTable checked={checked} checkFile={checkFileCallback}/>
            );
        }
    };

    /**
     * Hook used to determine whether to show the add metadata info.
     */
    useEffect(() => {
        const countMissingTitle = getNumMissingTitle(files, saved);
        setNumMissingTitle(countMissingTitle);
        if (0 === numberOfCheckedCallback() && numMissingTitle === files.length) {
            setShowAddMetadataInfo(true);
        }
    }, [files, saved]);

    return (
        <DamsForm
            onSubmit={saveDocumentsCallback}
            initialValues={formData}
            validationSchema={validationSchema}
        >
            <Paper elevation={3} sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
            }}>
                <Grid
                    container
                    direction={"row"}
                    alignContent={"flex-start"}
                    justifyContent={"flex-start"}
                >
                    <Grid item md={8}>
                        <div ref={toolbarRef}>
                            <UploadedFilesToolbar
                                checkAllCallback={checkAllCallback}
                                clearAllCallback={clearAllCallback}
                                numberOfCheckedCallback={numberOfCheckedCallback}
                            >
                                <Box sx={{flexGrow: 1}}/>
                                <ViewTypeOption
                                    viewType={viewType}
                                    setViewType={setViewTypeCallback}
                                />
                            </UploadedFilesToolbar>
                        </div>

                        {/* Uploaded files list */}
                        <Box sx={{paddingLeft: 2, paddingRight: 2}}>
                            {getViewBySelectedType()}
                        </Box>
                    </Grid>

                    <Grid item md={4}>
                        {showAddMetadataInfo && (
                            <Box sx={{paddingTop: 3, paddingRight: 2}}>
                                <AddMetadataInfoText/>
                            </Box>
                        )}

                        {0 < numberOfCheckedCallback() && (
                            <>
                                <Box
                                    sx={{
                                        display: "flex",
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                        paddingTop: 3,
                                    }}
                                >
                                    <NumberEditedInfo
                                        text={t(
                                            "editMetadataNumberOfCheckedObjects",
                                            "Rediger metadata for {{numChecked}} valgte bilder.",
                                            {numChecked: numberOfCheckedCallback()}
                                        )}
                                        onClick={clearAllCallback}/>
                                </Box>

                                <Box sx={{padding: 2}}>
                                    <BatchEditProvider>
                                        <FormMetadata disabled={0 === numberOfCheckedCallback()}
                                                      isProjectObject={false}
                                                      collectionId={formData.collectionId}
                                                      fileUploadMode={typeof (checkFileCallback) !== 'undefined'}/>
                                    </BatchEditProvider>
                                </Box>
                            </>
                        )}
                    </Grid>
                </Grid>

                {/* Bottom toolbar */}
                <Box padding={2}>
                    <Divider/>
                    <Box sx={{display: "flex", marginTop: 2, alignItems: "center"}}>
                        <InfoIcon sx={{marginRight: 1, color: "GrayText"}}/>
                        <Box>
                            <Typography color={"GrayText"}>
                                {t(
                                    "editMetadataMissingTitle",
                                    "{{numMissingTitle}} bilder mangler tittel",
                                    {numMissingTitle: numMissingTitle}
                                )}
                            </Typography>
                        </Box>
                        <Box flexGrow={1}/>
                        {numberOfCheckedCallback() > 0 && (
                            <Box>
                                <Button onClick={clearAllCallback} sx={{marginRight: 1}}>
                                    {t('btnCancel', 'Avbryt')}
                                </Button>
                                <FormikSubmitButton variant={"contained"} color={"secondary"}>
                                    {t("btnSaveEditedMetadata", "Lagre metadata")}
                                </FormikSubmitButton>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Paper>
        </DamsForm>
    );
};
