import React from "react";
import Grid from "@mui/material/Grid";
import {DroppableFolderCard} from "./DroppableFolderCard";
import {useMyMuseums} from "../../museum/useMyMuseums";
import {useAuthsState} from "../../auths/authsContext";
import {useFoldersState} from "../../folder/FoldersContext";
import {useMediaQuery} from "@mui/material";
import {ClickableFolderCard} from "./ClickableFolderCard";

/**
 * Renders the MyFoldersGrid component with folder information.
 *
 * @return {JSX.Element} The rendered MyFoldersGrid component.
 */
export const MyFoldersGrid = () => {
    const {results} = useFoldersState();
    const {museumCollections} = useAuthsState();

    const myMuseums = useMyMuseums({onlyAdministrativeMuseums: false});

    const smallScreen = useMediaQuery("(max-width: 1365px)");


    const getMuseumName = (collectionId) => {
        const museumsAndCollections = myMuseums.map((m) => {
            return {
                ...m,
                collectionId: museumCollections.find((mc) => mc.museumId === m.id)
                    ?.collectionId,
            };
        });
        const museum = museumsAndCollections?.find(
            (m) => m.collectionId === collectionId
        );
        return museum ? museum.name : "";
    };

    return (
        <Grid container spacing={1}>
            {results.models.map((f) => {
                const folder = {
                    id: f.id,
                    uniqueId: f.uniqueId,
                    name: f.title,
                    museumName: getMuseumName(f.collectionId),
                };
                return (
                    <Grid item
                          xs={3}
                          key={f.id}
                          sx={{
                              padding: smallScreen ? '0px' : '16px',
                              minWidth: '240px',
                          }}>
                        {!smallScreen ? <DroppableFolderCard folder={folder}/> : <ClickableFolderCard folder={folder}/>}
                    </Grid>
                );
            })}
        </Grid>
    );
};
