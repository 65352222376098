import {Box, Button, Paper, Typography} from "@mui/material";
import {AutoCompleteArcheologyProjectSearch} from "./AutoCompleteArcheologyProjectSearch";
import {If} from "../../../app/If";
import {damsFetch} from "../../../app/damsFetch";
import {ArcheologyProjectExists} from "./ArcheologyProjectExists";
import {
    ARCHEOLOGY_PROJECT_READ,
    CANCEL_CREATE_PROJECT,
    PROJECT_SET_COLLECTION_ID,
    useProjectDispatch,
    useProjectState,
    useProjectTranslation,
} from "../../projectContext";
import {ArcheologyProjectChosenProject} from "./ArcheologyProjectChosenProject";
import {SelectMuseumCollection} from "../../SelectMuseumCollection";
import React from "react";
import {getBrowserLocale} from "../../../utility";

/**
 * Component used to search for an existing archeology project on data.arkeologi.no.
 * @returns {JSX.Element}
 * @constructor
 */
export const ArcheologyRemoteSearchProject = () => {
    const t = useProjectTranslation();
    const projectDispatch = useProjectDispatch();
    const language = getBrowserLocale(true);

    const {projectData, damsProject, collectionId} = useProjectState();

    /**
     * Checks if a project already exists in DAMS or not.
     * @param fetched
     */
    const projectExists = (fetched) => {
        damsFetch("/projects/archeology/exists/", {
            method: "POST",
            body: JSON.stringify({unique_id: fetched.externalProjectUUID}),
        }).then((res) => {
            projectDispatch({
                type: ARCHEOLOGY_PROJECT_READ,
                project: res,
                projectData: {
                    ...projectData,
                    archeologyRemote: fetched,
                },
            });
        });
    };

    const getSystemIdAndName = (data) => {
        if (!data["investigationInvestigatesMonumentId"]) {
            return undefined;
        }

        if (data["investigationInvestigatesMonumentId"].length === 0) {
            return undefined;
        }

        const refNode = data["investigationInvestigatesMonumentId"][0];
        const refNodeProps = refNode.value.properties;
        const systemId = refNodeProps["alternativeIdId"][0].value;
        const systemName = refNodeProps["alternativeIdSystem"][0].value;

        return {id: systemId, name: systemName};
    };


    /**
     * Callback that handles the results from a search for a project
     * on arkeologi.org.
     * @param data JSON
     */
    const searchCallback = (data) => {
        const fetched = data.entities.map((m) => {
            const {properties, uuid, caption, updatedAt} = m;
            const {
                investigationId,
                investigationType,
                superconceptBegin,
                superconceptEnd,
                superconceptStatus
            } = properties;
            let obj = {
                externalProjectUUID: uuid,
                name: caption[language] || caption['*'],
                projectId: investigationId[0].value,
                projectType: investigationType[0].displayValue[language],
                projectStart: superconceptBegin[0].value,
                projectEnd: superconceptEnd[0].value,
                projectStatus:
                    superconceptStatus[0].displayValue[language],
                updatedAt: updatedAt,
                projectDescription: getDescription(properties),
                projectMonumentType: getMonumentType(properties),
                projectMonumentPeriod: getMonumentPeriod(properties),
                projectDataset: getProjectDataset(m),
                projectLatLng: getLatLng(m),
            };

            const sysIdAndName = getSystemIdAndName(properties);
            if (sysIdAndName) {
                obj["projectSystemId"] = sysIdAndName.id;
                obj["projectSystemName"] = sysIdAndName.name;
            }

            return obj;
        })[0];
        projectExists(fetched);
    };

    /**
     * Gets the description.
     * @param node
     * @returns {string|*}
     */
    const getDescription = node => {
        if (
            node["entityDescription"] === undefined ||
            node["entityDescription"].length === 0
        ) {
            return "";
        }
        const descNode = node["entityDescription"][0];
        if (descNode.value && descNode.value[language]) {
            return descNode.value[language];
        } else {
            return "";
        }
    };

    /**
     * Gets the monument type.
     * @param node
     * @returns {string|*|string}
     */
    const getMonumentType = node => {
        if (
            node["investigationCulturalHistoryResult"] === undefined ||
            node["investigationCulturalHistoryResult"].length === 0
        ) {
            return "";
        }
        const culturalHistoryResultNodeValue =
            node["investigationCulturalHistoryResult"][0].value || undefined;
        if (!culturalHistoryResultNodeValue) {
            return "";
        }
        const monumentNode =
            culturalHistoryResultNodeValue.properties["culturalHistoryResultMonumentType"] || undefined;
        if (!monumentNode) {
            return "";
        }
        return monumentNode[0].displayValue[language] || "";
    };

    /**
     * Get the monumental periods!
     * @param node
     * @returns {string|*|string}
     */
    const getMonumentPeriod = node => {
        if (
            node["investigationCulturalHistoryResult"] === undefined ||
            node["investigationCulturalHistoryResult"].length === 0
        ) {
            return "";
        }
        const culturalHistoryResultNodeValue =
            node["investigationCulturalHistoryResult"][0].value || undefined;
        if (!culturalHistoryResultNodeValue) {
            return "";
        }
        const periodNode =
            culturalHistoryResultNodeValue.properties["culturalHistoryResultPeriod"] || undefined;
        if (!periodNode) {
            return "";
        }
        return periodNode[0].displayValue[language] || "";
    };


    /**
     * Get the geographical koordinates.
     * @param value json    A JSON-object representing a sub-portion of the model object.
     * @returns {{lng, lat}}
     */
    const getLatLng = value => {
        let lat;
        let lng;

        const {entityLatitude, entityLongitude} = value.properties;

        if (entityLatitude && entityLatitude.length > 0) {
            lat = entityLatitude[0].value;
        }

        if (entityLongitude && entityLongitude.length > 0) {
            lng = entityLongitude[0].value;
        }

        return {lat: lat, lng: lng};
    };


    /**
     * Gets the name of the dataset the archeology project is related to.
     * @param m object  The model
     * @returns {string}
     */
    const getProjectDataset = m => {
        const {properties} = m;
        let projectDataset = "";
        const {entityDataset} = properties;
        if (entityDataset) {
            projectDataset = entityDataset[0].displayValue;
        }
        return projectDataset;
    };

    /**
     * Callback used when the user has selected the museum the project will be associated with.
     * @param mId int  Museum ID
     * @param cId int  Collection ID
     */
    const museumCollectionCallback = (mId, cId) => {
        // CollectionID is used when saving the project, in order to associate it with the correct museum.
        projectDispatch({
            type: PROJECT_SET_COLLECTION_ID,
            collectionId: cId,
            museumId: mId,
        });
    };

    return (
        <Paper
            sx={{
                width: "100%",
                minWidth: '100%',
                padding: '32px',
                display: "flex",
                flexDirection: "column",
            }}
        >
            <If boolean={!projectData.archeologyRemote}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "left",
                    }}
                >
                    <Typography variant={"h6"} sx={{marginBottom: '16px'}}>
                        {t('headingArcheologyProject', 'Arkeologiprosjekt')}
                    </Typography>
                    <Typography
                        variant={"h5"}
                        sx={{display: "block", marginBottom: '16px'}}
                    >
                        {t('chooseMuseumAndProject', 'Velg museum og prosjekt')}
                    </Typography>

                    {/* Select museum */}
                    <Box>
                        <SelectMuseumCollection callback={museumCollectionCallback}/>
                    </Box>
                    <AutoCompleteArcheologyProjectSearch
                        formikKey={"projectUUID"}
                        language={language}
                        metadataCallback={searchCallback}
                        disabled={!collectionId}
                    />
                </Box>
            </If>
            <If
                boolean={
                    Object.keys(damsProject).length === 0 && projectData.archeologyRemote
                }
            >
                <ArcheologyProjectChosenProject/>
            </If>

            <If
                boolean={
                    Object.keys(damsProject).includes("id") &&
                    projectData.archeologyRemote
                }
            >
                <ArcheologyProjectExists/>
            </If>

            <If boolean={!projectData || !projectData.archeologyRemote}>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "right",
                    }}
                >
                    <Button
                        color={"secondary"}
                        onClick={() => {
                            projectDispatch({
                                type: CANCEL_CREATE_PROJECT,
                            });
                        }}
                    >
                        {t('btnCancel', 'Avbryt')}
                    </Button>
                </Box>
            </If>
        </Paper>
    );
};
