import "./styles.css";
import React, {useCallback, useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {Document, Page} from "react-pdf/dist/esm/entry.webpack5";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {pdfjs} from "react-pdf";
import {ZoomToolbar} from "./ZoomToolbar";
import {NavigationToolbar} from "./NavigationToolbar";
import {If} from "../app/If";
import {useSearchResultDetailsTranslation} from "../search-result-details/SearchResultDetailsContext";
import {damsFetchPDF} from "../app/damsFetch";
import Grid from "@mui/material/Grid";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {Dialog, DialogContent, DialogTitle, LinearProgress,} from "@mui/material";
import {clientLog} from "../clientLog";

const PREFIX = "PDFViewer";

const classes = {
    container: `${PREFIX}-container`,
    document: `${PREFIX}-document`,
    page: `${PREFIX}-page`,
    pagerPages: `${PREFIX}-pagerPages`,
};

const Root = styled("div")(() => ({
    [`&.${classes.container}`]: {
        position: "fixed",
        top: 0,
        left: 0,
        minWidth: "100vw",
        minHeight: "100vh",
        background: "black",
        zIndex: 10000,
    },

    [`& .${classes.document}`]: {
        height: "90vh",
        width: "100vw",
        overflow: "auto",
        background: "gray",
        alignItems: "center",
        justifyContent: "center",
    },

    [`& .${classes.page}`]: {
        height: "100%",
        width: "100%",
    },

    [`& .${classes.pagerPages}`]: {
        width: "100%",
        textAlign: "center",
        position: "relative",
        top: "24px",
        color: "black",
        zIndex: "10000",
    },
}));

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const PDFViewer = ({model, closeCallback}) => {

    const componentName = 'PDFViewer';

    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [showPageNavigator, setShowPageNavigator] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(4.0);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const t = useSearchResultDetailsTranslation();

    const onDocumentLoadSuccess = (pdf) => {
        setNumPages(pdf?.numPages);
        setShowPageNavigator(true);
    };

    const onSourceError = (error) => {
        clientLog('error', error, componentName);
    };

    const onLoadError = ({error}) => {
        clientLog('error', error, componentName);
    };

    const getContents = useCallback(() => {
        const collectionId = model.collectionId;
        const dmsId = model.content.mediae[0].reference.sourceId;
        const options = {
            method: "POST",
            body: JSON.stringify(
                decamelizeKeysDeep({
                    collectionId: collectionId,
                    multimediaId: dmsId,
                })
            ),
        };
        damsFetchPDF(`/dms/download-pdf/`, options).then((d) => {
            setData(d);
            setLoading(false);
        });
    }, [model.collectionId, model.content.mediae]);

    const renderPage = useCallback(() => {
        return <Page
            className={classes.page}
            pageNumber={currentPage}
            renderTextLayer={false}
            loading={t('pageLoading', 'Laster ny side...')}
            scale={zoomLevel}
            width={400}
        />;
    }, [currentPage, zoomLevel]);


    useEffect(() => {
        getContents();
    }, [getContents]);

    return (
        <Root className={classes.container}>
            <If boolean={showPageNavigator}>
                <ZoomToolbar callback={setZoomLevel}/>
                <NavigationToolbar
                    numPages={numPages}
                    currentPage={currentPage}
                    callback={setCurrentPage}
                    closeCallback={closeCallback}
                />
            </If>
            <Grid container wrap="nowrap">
                <Grid item md={12}>
                    <Document
                        loading={t("txtLoadingPDF", "Laster PDF...")}
                        noData={""}
                        className={classes.document}
                        file={data}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onSourceError={onSourceError}
                        onLoadError={onLoadError}
                    >
                        {renderPage()}
                    </Document>
                </Grid>
            </Grid>
            <Dialog open={loading}>
                <DialogTitle>{t('txtLoadingPDF', 'Laster PDF....')}</DialogTitle>
                <DialogContent
                    sx={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                    }}
                >
                    <LinearProgress sx={{width: "100%"}}/>
                </DialogContent>
            </Dialog>
        </Root>
    );
};
