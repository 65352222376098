import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {createTheme, ToggleButtonGroup, Toolbar, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import Typography from "@mui/material/Typography";
import {
    SET_ROWS,
    SET_SEARCHING,
    SET_SORT,
    SET_START,
    useSearchDispatch,
    useSearchState,
    useSearchTranslation,
} from "../search/SearchContext";
import {getDefaultDocumentTypesConfig, useDocumentTypeState} from "../document-type/documentTypeContext";
import {useMuseumState} from "../museum/MuseumContext";
import {ADD_MESSAGE, useSnackbarDispatch,} from "../snackbar/SnackbarContext";
import {useSearch} from "../search/useSearch";
import {ResultGrid} from "../search/ResultGrid";
import {ResultTable} from "../search/ResultTable";
import {SearchResultDetails} from "../search-result-details/SearchResultDetails";
import {SearchResultsActions} from "../search/SearchResultsActions";
import {SearchResultsOrderByMenu} from "../search/SearchResultsOrderByMenu";
import {FilterSearch} from "../search/filters/FilterSearch";
import {ResultCard} from "../search/resultcard/ResultCard";
import {useNavigate} from "react-router-dom";
import {useAuthsState} from "../auths/authsContext";
import {damsFetch} from "../app/damsFetch";
import {SearchSettings} from "../search/SearchSettings";
import {
    SET_PROJECT_VIEW_FACETS,
    useProjectViewDispatch,
    useProjectViewState
} from "./archeology/projectview/projectViewContext";
import {ResultTableProvider} from "../search/ResultTableContext";
import {useProjectTranslation} from "./projectContext";
import {SET_PROJECT, useFileUploadDispatch} from "../damsfileupload/fileUploadContext";
import {clientLog} from "../clientLog";
import IconButton from "@mui/material/IconButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import useDeepCompareEffect from "use-deep-compare-effect";
import {isProjectActive} from "./projectUtility";
import GridViewIcon from "@mui/icons-material/GridViewSharp";
import ViewListIcon from "@mui/icons-material/ViewList";
import {ThemeProvider} from "@mui/styles";
import * as locales from "@mui/material/locale";
import i18next from "i18next";
import TablePagination from "@mui/material/TablePagination";

const componentName = 'ProjectContentsSearchResults';
const PREFIX = componentName;

const classes = {
    searchResultsPaper: `${PREFIX}-searchResultsPaper`,
    verticalDivider: `${PREFIX}-verticalDivider`,
    divider: `${PREFIX}-divider`,
};

const StyledPaper = styled(Paper)(({theme}) => ({
    [`&.${classes.searchResultsPaper}`]: {
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        flexGrow: 1,
        padding: theme.spacing(0), // Use padding in children
        overflow: 'hidden',
    },

    [`& .${classes.verticalDivider}`]: {
        margin: 0,
    },

    [`& .${classes.divider}`]: {
        margin: 0,
    },
}));

export const ProjectContentsSearchResults = ({showInstantly}) => {

    const t = useSearchTranslation();
    const tp = useProjectTranslation();

    const [data, setData] = useState();

    const {userData} = useAuthsState();
    const {project} = useProjectViewState();

    const {id, content} = project;
    const {acls} = content;

    const projectActive = isProjectActive(project);

    const [hasWriteAccess, setHasWriteAccess] = useState(false);

    const {museums} = useMuseumState();

    const projectSearchSettings = SearchSettings('dams.projectSearchResults.config');

    const [viewMode, setViewMode] = useState(projectSearchSettings.getDisplayType());

    const navigate = useNavigate();

    const documentTypeState = useDocumentTypeState();

    const searchDispatch = useSearchDispatch();
    const snackbarDispatch = useSnackbarDispatch();
    const projectViewDispatch = useProjectViewDispatch();

    const fileUploadDispatch = useFileUploadDispatch();

    const smallScreen = useMediaQuery("(max-width: 768px)");

    const [locale] = useState(i18next.language === "nbNO" ? "svSE" : "enUS");

    const currentNumRows = projectSearchSettings.getNumberOfRows();

    const handleChangePage = (_event, newPage) => {
        searchDispatch({
            type: SET_START,
            start: newPage * currentNumRows,
        });
        searchDispatch({
            type: SET_SEARCHING
        });
    };

    const handleChangeRowsPerPage = (event) => {
        projectSearchSettings.setNumberOfRows(event.target.value);

        searchDispatch({
            type: SET_ROWS,
            rows: event.target.value,
        });

        searchDispatch({
            type: SET_SEARCHING
        });
    };


    /**
     * Hook used to determine whether the current user has write access to the project.
     */
    useEffect(() => {
        if (project) {
            damsFetch("/current-user/my-dams-id/")
                .then((json) => {
                    if (json["id"] === project["createdById"]) {
                        setHasWriteAccess(true);
                    } else if (acls) {
                        const res = acls.find(a =>
                            a.email.toLowerCase() === userData.email.toLowerCase()
                            && a.access === "write");
                        if (res) {
                            setHasWriteAccess(true);
                        }
                    }
                })
                .catch(error => {
                    clientLog('error', error, componentName);
                });
        }
    }, [project, acls, userData.email]);

    const {
        query,
        fl,
        start,
        expand,
        facetField,
        statsField,
        fq,
        results,
        selected,
        triggerSearch,
        onlyMine,
        searching,
    } = useSearchState();


    const documentTypes = documentTypeState.documentTypes || getDefaultDocumentTypesConfig(true);
    const rows = projectSearchSettings.getNumberOfRows();
    const sort = projectSearchSettings.getSortCriteria();

    const criterias = {
        query: query,
        sort: `${sort}`,
        fl: fl,
        start: start,
        rows: rows,
        expand: expand,
        documentType: documentTypes,
        facetField: facetField,
        statsField: statsField,
        projectContext: true,
        fq: fq,
        museums,
        triggerSearch,
        onlyMine: onlyMine,
    };

    useSearch(criterias);

    const onTypeChange = (_event, newViewMode) => setViewMode(newViewMode);

    /**
     * File upload V2
     */
    const handleButtonUploadV2Click = () => {
        if (!project) {
            return;
        }

        fileUploadDispatch({
            type: SET_PROJECT,
            project: project,
        });

        setTimeout(() => {
            navigate('/upload');
        }, 300);
    };

    const RESULT_MODE = {
        buttonUpload: (
            <Grid
                container
                direction={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                spacing={2}
                height={"100%"}
            >
                <Grid item>
                    <Typography variant={"body1"}>
                        {tp('headingNoFilesInProject', 'Det er foreløpig ingen filer i prosjektet.')}
                    </Typography>
                </Grid>
                <Grid item>
                    {hasWriteAccess && <Button sx={{marginLeft: '8px'}}
                                               startIcon={<CloudUploadIcon/>}
                                               variant={"contained"}
                                               color={"secondary"}
                                               onClick={handleButtonUploadV2Click}
                                               disabled={!projectActive}>{tp('uploadFiles', 'Last opp filer')}</Button>
                    }
                </Grid>
            </Grid>
        ),
        grid: (
            <ResultGrid>
                {data?.models.map((model) => (
                    <ResultCard
                        model={model}
                        key={model.id}
                        selected={Boolean(
                            selected.find(
                                (selectedModel) => selectedModel.uniqueId === model.uniqueId
                            )
                        )}
                        showInstantly={showInstantly}
                    />
                ))}
            </ResultGrid>
        ),
        table: <ResultTableProvider><ResultTable dataRows={data?.models}/></ResultTableProvider>,
    };

    const handleError = () => {
        clientLog('error', JSON.stringify(data?.error), componentName);
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t("searchFailedErrorTitle", "Søk feilet"),
                body: t("searchFailedErrorMessage", "Har du valgt dokumenttype?"),
                type: "error",
            },
        });
    };

    const getUploadButton = () => {
        return <>
            {smallScreen && hasWriteAccess && (
                <IconButton
                    onClick={handleButtonUploadV2Click}
                    color={"secondary"}
                    title={tp('uploadFiles', 'Last opp filer')}
                    disabled={!projectActive}
                >
                    <CloudUploadIcon/>
                </IconButton>
            )}
            {!smallScreen && hasWriteAccess && (
                <>
                    <Button sx={{marginLeft: '8px'}}
                            startIcon={<CloudUploadIcon/>}
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={handleButtonUploadV2Click}
                            disabled={!projectActive}>
                        {tp('upload', 'Last opp')}
                    </Button>
                </>
            )}
        </>;
    };

    useEffect(() => {
        if (!data) {
            return;
        }
        projectViewDispatch({type: SET_PROJECT_VIEW_FACETS, facets: data?.facets});
    }, [data, projectViewDispatch]);

    /**
     * Hook used to copy the loaded data into local state-variable.
     */
    useDeepCompareEffect(() => {
        if (results.error) {
            handleError();
        }

        if (results.count >= 0) {
            setData({...results});
        }
    }, [results]);

    const [displayCount, setDisplayCount] = useState(results.count);

    /**
     * Hook used to update the displayCount variable.
     */
    useEffect(() => {
        if (!searching) {
            setDisplayCount(results.count);
        }
    }, [results.count, searching, viewMode]);

    /**
     * Hook used to display the "NoHits" information, when a search yields no results.
     */
    useEffect(() => {
        if (!searching && results.count <= 0 && results.models.length === 0) {
            setDisplayCount(0);
        }
    }, [searching, results.count, results.models.length]);

    /**
     * Hook used to run the initial search.
     */
    useEffect(() => {
        searchDispatch({type: SET_SEARCHING});
    }, [])

    const page = start / currentNumRows;

    return (
        <StyledPaper className={classes.searchResultsPaper} sx={{
            marginTop: {
                xs: 0,
                md: 1,
            },
            marginInline: {
                xs: 0,
                md: 3,
            },
            marginBottom: {
                xs: 0,
                md: 3,
            },
            borderRadius: {
                xs: 0,
                md: 1,
            },
        }} id="projects-search-results-paper">
            <SearchResultDetails/>
            <Box sx={{display: 'flex', flexDirection: 'row', overflow: 'hidden', flexGrow: 1}}>
                <Box sx={{flexGrow: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>

                    <Toolbar sx={{
                        display: 'flex',
                        flexShrink: 1,
                        flexWrap: 'wrap',
                        gap: '.25rem',
                        alignItems: 'center',
                        "&.MuiToolbar-root": {
                            paddingLeft: 2,
                            paddingRight: 1,
                            minHeight: 'auto',
                        }
                    }}>

                        <Typography variant="h5" component="h2" paddingBlock={'.75rem'} id={"search-results-title"}
                                    sx={{
                                        paddingRight: 1,
                                    }}>{t('projectFiles', 'Prosjektfiler')}</Typography>


                        <Hidden smDown={true}>
                            <ToggleButtonGroup
                                value={viewMode}
                                exclusive
                                onChange={onTypeChange}
                            >
                                <ToggleButton value="grid" aria-label="grid" size={"small"} sx={{padding: '5px',}}>
                                    <GridViewIcon/> {/* Grid */}
                                </ToggleButton>
                                <ToggleButton value="table" aria-label="table" size={"small"} sx={{padding: '5px',}}>
                                    <ViewListIcon/> {/* Table */}
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Hidden>

                        <Hidden mdDown={true}>
                            <SearchResultsOrderByMenu
                                sort={sort}
                                t={t}
                                selectHandler={(value) => {
                                    searchDispatch({
                                        type: SET_SORT,
                                        sort: value,
                                    });
                                    setTimeout(() => {
                                        searchDispatch({type: SET_SEARCHING})
                                    }, 500);
                                }}
                            />
                        </Hidden>

                        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 'auto'}}>
                            <SearchResultsActions project={project}/>

                            <ThemeProvider
                                theme={createTheme({}, locales[locale])}
                            >

                                <TablePagination
                                    sx={{
                                        flexShrink: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        minHeight: {
                                            xs: "auto",
                                            md: '4rem',
                                        },
                                        "& .MuiTablePagination-toolbar": {
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                        },
                                        "& .MuiTablePagination-input": {
                                            marginLeft: 0,
                                            marginRight: 0.5,
                                        },
                                        "& div.MuiTablePagination-actions": {
                                            marginLeft: .5,
                                        }
                                    }}
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    component="div"
                                    count={displayCount}
                                    rowsPerPage={projectSearchSettings.getNumberOfRows()}
                                    page={page}
                                    labelRowsPerPage={t('show', 'Vis')}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    labelDisplayedRows={({from, to, count}) =>
                                        t("labelDisplayedRows", "{{from}}-{{to}} av {{count}}", {
                                            from: from,
                                            to: to,
                                            count: count,
                                        })
                                    }
                                />
                            </ThemeProvider>

                            {getUploadButton()}
                        </Box>

                    </Toolbar>
                    <Divider className={classes.divider}/>
                    {/* Search results */}
                    <Box id="results-area" sx={{
                        padding: 0,
                        margin: 0,
                        overflow: 'hidden', // As tempting as it is to turn on scroll here, do it inside the results table/grid.
                        display: 'flex',
                        flexGrow: 1,
                        flexShrink: 1,
                    }}>
                        {(data && data?.count > 0) && RESULT_MODE[viewMode]}
                        {(!data || data?.count === 0) && RESULT_MODE['buttonUpload']}
                    </Box>


                </Box>

                {/* Right column: filters */}
                <Hidden mdDown={true}>
                    <Divider
                        orientation="vertical"
                        flexItem
                        className={classes.verticalDivider}
                    />
                    {/* Flex child for the filters: */}
                    <Box sx={{
                        padding: 0,
                        margin: 0,
                        maxWidth: {
                            sm: '23rem',
                            xl: '29rem',
                        },
                        flexBasis: {
                            sm: '23rem',
                            xl: '29rem',
                        },
                        flexShrink: 0,
                        display: 'flex'
                    }}>
                        <FilterSearch projectId={id}/>
                    </Box>

                </Hidden>
            </Box>
        </StyledPaper>
    );
};
