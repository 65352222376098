import React, {useState} from "react";
import {UploadedFiles} from "../UploadedFiles";
import {useMarkFiles} from "../useMarkFiles";
import {DraggableFilesGrid} from "../DraggableFilesGrid";
import {useFolderTranslation,} from "../../folder/MyFoldersContext";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import {CreateFolderButton} from "../../metadata/smallscreen/CreateFolderButton";
import {CheckBoxOutlineBlankOutlined, CheckBoxOutlined, IndeterminateCheckBoxOutlined,} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {useGetUploadFolders} from "../../folder/useGetUploadFolders";
import {useDocumentState} from "../../documents/documentContext";
import {useFoldersState} from "../../folder/FoldersContext";
import {ButtonAddToFolder} from "../../metadata/smallscreen/ButtonAddToFolder";

export const AddToFolderStepSmallScreen = () => {
    const t = useFolderTranslation();
    const {files} = useDocumentState();
    const {results: folders} = useFoldersState();

    useGetUploadFolders({fileUploaderV2: false});

    const [
        checked,
        checkFile,
        checkAll,
        clearAll,
        getCheckedFilenames,
        numberOfChecked,
    ] = useMarkFiles(files);

    const [showFolders, setShowFolders] = useState(false);

    const getCheckIcon = (_) => {
        const numChecked = numberOfChecked();
        if (numChecked === 0) {
            return <CheckBoxOutlineBlankOutlined onClick={() => checkAll()}/>;
        } else if (numChecked > 0 && numChecked !== files.length) {
            return <IndeterminateCheckBoxOutlined/>;
        } else {
            return <CheckBoxOutlined onClick={() => clearAll()}/>;
        }
    };

    const handleChooseFolderClick = () => {
        setShowFolders(true);
    };

    return (
        <Box>
            <UploadedFiles>
                <Box sx={{display: "flex", alignItems: "center"}}>
                    {getCheckIcon()}
                    <Typography variant={"h6"} marginLeft={1}>
                        {t("uploaded", "Opplastet")} ({numberOfChecked()})
                    </Typography>
                    <Box flexGrow={1}/>
                    <Button
                        onClick={handleChooseFolderClick}
                        disabled={numberOfChecked() === 0 || showFolders}
                    >
                        {t("chooseFolder", "Velg mappe")}
                    </Button>
                </Box>
                <DraggableFilesGrid checked={checked} checkFile={checkFile}/>
            </UploadedFiles>
            {/* list of folders */}
            <Dialog open={showFolders} fullScreen scroll={"paper"}>
                <DialogTitle sx={{display: "flex", width: "100vw"}}>
                    <Box>
                        <Typography variant={"h6"}>Mapper</Typography>
                        <Typography variant={"caption"}>
                            {t(
                                "clickDestinationFolder",
                                "Klikk på mappen du ønsker å legge filene i."
                            )}
                        </Typography>
                    </Box>
                    <Box sx={{flexGrow: 1}}/>
                    <CloseIcon
                        onClick={() => setShowFolders(false)}
                        sx={{
                            color: "GrayText",
                        }}
                    />
                </DialogTitle>
                <DialogContent>
                    <CreateFolderButton/>
                    <Grid
                        container
                        direction={"row"}
                        spacing={1}
                        sx={{
                            marginTop: 2,
                        }}
                    >
                        {folders.models.map((folder) => {
                            return (
                                <Grid item key={folder.title}>
                                    <ButtonAddToFolder
                                        folder={folder}
                                        checkedFilenames={getCheckedFilenames()}
                                        closeDialogCallback={() => {
                                            setShowFolders(false);
                                        }}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </DialogContent>
            </Dialog>
        </Box>
    );
};
