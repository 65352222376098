import React, {useEffect, useState} from "react";
import {Stack, Toolbar, useTheme} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useFoldersTranslation} from "./FoldersContext";
import {useAuthsState} from "../auths/authsContext";
// noinspection ES6CheckImport
import {useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

export const FolderTitleToolbar = ({
                                       tags,
                                       folderTitle,
                                       editFolderButton,
                                       searchOnEnterCallback,
                                       inputValue,
                                       setInputValue,
                                   }) => {
    const {userData} = useAuthsState();
    const navigate = useNavigate();
    const theme = useTheme();
    const t = useFoldersTranslation();

    const [externalUser, setExternalUser] = useState(true);
    const [folderTags, setFolderTags] = useState([]);

    /**
     * Hook used to keep the folder tags in sync.
     */
    useEffect(() => {
        setFolderTags(tags);
    }, [tags]);

    useEffect(() => {
        if (userData['appAccess']?.museums.length > 0) {
            setExternalUser(false);
        }
    }, [userData, externalUser]);


    const renderBackArrow = () => {
        if (externalUser) {
            return <></>;
        }

        return <IconButton
            sx={{color: "black"}}
            onClick={() => navigate(-1)}
            size="large"
        >
            <ArrowBackIcon sx={{height: 30, width: 30}}/>
        </IconButton>;
    };

    const renderTags = () => {
        if (!folderTags || folderTags?.length === 0) {
            return <></>;
        }

        return <Box>{folderTags.map(t => {
            const title = t?.reference?.title || t?.title;
            return <Chip key={title} label={title} color={"secondary"} sx={{marginRight: '8px'}}/>
        })}</Box>;
    }

    return <Toolbar sx={{
        marginLeft: theme.spacing(6),
        marginTop: theme.spacing(4),
        padding: 0,
        flexWrap: "wrap",
        marginBottom: theme.spacing(1),
    }}>
        <Grid container direction={"column"}>
            <Grid item>
                <Stack direction={"row"} alignItems={"center"}>
                    {
                        renderBackArrow()
                    }
                    <Typography variant={"h5"}>{folderTitle}</Typography>
                    {
                        !externalUser && editFolderButton
                    }
                    <TextField
                        sx={{maxWidth: '90%'}}
                        id="dams-search"
                        label={t("searchInFolder", "Søk i mappe")}
                        type="search"
                        variant="filled"
                        onKeyDown={searchOnEnterCallback}
                        value={inputValue}
                        fullWidth={true}
                        onChange={(event) => setInputValue(event.target.value)}>
                    </TextField>
                </Stack>
            </Grid>
            <Grid item>
                {
                    renderTags()
                }
            </Grid>
        </Grid>
    </Toolbar>;
};
