import {damsProjectTypes} from "./damsProjectTypes";

const _numberToSixDecimals = (n) => {
    if (!n) {
        return 0;
    }
    return parseFloat(n.toFixed(6));
};

const _parseKulturNavDate = (value) => {
    if (!value) {
        return "";
    }
    if (value.indexOf("-Y") !== -1) {
        return value.replace("-Y", "");
    }
};

export const getHrLatLngStr = (lat, lng) => {
    if (!lat || !lng) {
        return '';
    }

    try {
        return _numberToSixDecimals(lat) + ", " + _numberToSixDecimals(lng) + " (WGS84)"
    } catch (error) {
        if (error.message === 'n.toFixed is not a function') {
            throw new Error('lat-/lng argument(s) must be float value(s)');
        }
    }
};

export const getHrDateTime = (dateTime, language) => {
    if (dateTime === '') {
        return '';
    }

    if (!dateTime) {
        throw Error('argument dateTime, must be a valid JavaScript Date object or a valid date-/time string');
    }

    const df = new Intl.DateTimeFormat(language, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        fractionalSecondDigit: 0,
    });

    const d = new Date(dateTime);
    return df.format(d).replace(",", "");
};

export const getProjectPeriod = (startedAt, endedAt) => {
    if (startedAt && endedAt) {
        return (
            _parseKulturNavDate(startedAt) + " - " + _parseKulturNavDate(endedAt)
        );
    } else if (!startedAt && endedAt) {
        return "- " + _parseKulturNavDate(endedAt);
    } else if (startedAt && !endedAt) {
        return _parseKulturNavDate(startedAt) + " -";
    }

    return '';
};

/**
 * Gets the content selector, matching the project type of the model.
 * The content selector is used when extracting metadata for the project from
 * the model['content'][<content selector>] property.
 *
 * Project types:
 * ProjectArcheology => selector: archeologyprojectref
 * ProjectGeneral => selector: generalprojectref
 *
 * @param model obj The project model object.
 * @returns {string}
 */
export const getContentSelector = (model) => {
    if (!model) {
        return null;
    }

    const {folderType: projectType} = model;
    if (!projectType) {
        throw new Error('Unable to determine project type, projectType property not present');
    }

    if (!damsProjectTypes.includes(projectType)) {
        throw new Error('The specified project type is not supported');
    }

    return projectType === 'ProjectGeneral' ? 'generalprojectref' : 'archeologyprojectref';
};

/**
 * Returns true | false, whether the project is active or not.
 * @param model obj The project model object.
 * @returns {boolean|*}
 */
export const isProjectActive = (model) => {
    if (!model) {
        throw new Error('model object not specified');
    }

    const {content} = model;
    const selector = getContentSelector(model);

    if (!selector) {
        return false;
    }

    return typeof (content[selector].active) === 'undefined'
        ? true
        : content[selector].active;
};