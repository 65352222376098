import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {ObjectViewMediaSection} from "./ObjectViewMediaSection";
import {makeStyles} from "@mui/styles";
import {useObserveWindowResize} from "../app/useObserveWindowResize";

const useStyles = makeStyles(() => ({
    portraitBox: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        height: "30vh !important",
        maxHeight: "30vh !important",
        backgroundColor: "#ddd",
    },
    landscapeBox: {
        display: "flex",
        width: "30%",
        minHeight: "100vh",
        alignContent: "center",
        justifyContent: "center",
        backgroundColor: "#ddd",
    },
}));

/**
 * Wrapper used to adapt the object view media section to a small or large screen.
 * @param smallScreen   bool    True, if displayed on a small screen
 * @param columnDefs    Array   Array of column defs [left, right]
 * @param gridRef   ref Reference to the mete section container
 * @param handleMediaPlayClick function Callback when play icon is clicked
 * @returns {JSX.Element}
 * @constructor
 */
export const ObjectViewMediaSectionWrapper = ({
                                                  smallScreen,
                                                  columnDefs,
                                                  gridRef,
                                                  t,
                                                  mediaPlayCallback,
                                                  pdfOpenedCallback
                                              }) => {
    const observeWindowResize = useObserveWindowResize();
    const classes = useStyles();
    const [isPortrait, setIsPortrait] = useState(true);

    /**
     * Hook used to see if the application is running in portrait mode or not.
     */
    useEffect(() => {
        setIsPortrait(observeWindowResize.width < observeWindowResize.height);
    }, [setIsPortrait, observeWindowResize]);

    return (
        <>
            {!smallScreen && (
                <Grid item md={columnDefs[0]} ref={gridRef}>
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            justifyContent: "center",
                        }}
                    >
                        <ObjectViewMediaSection
                            t={t}
                            gridRef={gridRef}
                            mediaPlayCallback={mediaPlayCallback}
                            smallScreen={smallScreen}
                            pdfOpenedCallback={pdfOpenedCallback}
                        />
                    </Box>
                </Grid>
            )}
            {smallScreen && (
                <Box
                    className={isPortrait ? classes.portraitBox : classes.landscapeBox}
                >
                    <ObjectViewMediaSection
                        gridRef={gridRef}
                        mediaPlayCallback={mediaPlayCallback}
                        smallScreen={smallScreen}
                        t={t}
                        pdfOpenedCallback={pdfOpenedCallback}
                    />
                </Box>
            )}
        </>
    );
};
