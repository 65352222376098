import Box from "@mui/material/Box";
import {Formik} from "formik";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import {FormikSelect} from "../../form/FormikSelect";
import {ProjectTypes} from "./wizard/projectTypes";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import {FormikTextField} from "../../form/FormikTextField";
import {FormikDateField} from "../../form/FormikDateField";
import {FormikSwitch} from "../../form/FormikSwitch";
import {FormikSelectDamsUsers} from "../FormikSelectDamsUsers";
import Button from "@mui/material/Button";
import {FormikSubmitButton} from "../../form/FormikSubmitButton";
import React, {useEffect} from "react";
import * as Yup from "yup";
import {useAuthsState} from "../../auths/authsContext";
import {useMyMuseums} from "../../museum/useMyMuseums";
import {useNavigate} from "react-router-dom";
import {useSaveGeneralProject} from "./useSaveGeneralProject";
import makeStyles from "@mui/styles/makeStyles";
import {clientLog} from "../../clientLog";
import Paper from "@mui/material//Paper";
import Typography from "@mui/material/Typography";
import {useProjectViewState} from "../archeology/projectview/projectViewContext";
import {getMuseumIdFromCollectionId} from "../../utility";
import {PROJECT_SET_COLLECTION_ID, useProjectDispatch, useProjectState, useProjectTranslation} from "../projectContext";
import {FormikSelectProjects} from "../FormikSelectProjects";
import Fade from "@mui/material/Fade";
import useDeepCompareEffect from "use-deep-compare-effect";
import {idExists} from "./wizard/idExists";

const useStyles = makeStyles(() => ({
    box: {
        marginBottom: '16px',
    }
}));

export const GeneralProjectForm = ({collectionId, initialValues}) => {

    const {project} = useProjectViewState();
    const {museumId} = useProjectState();
    const projectDispatch = useProjectDispatch();

    const {save, saveState} = useSaveGeneralProject();

    const {museumCollections} = useAuthsState();
    const myMuseums = useMyMuseums({onlyAdministrativeMuseums: false});

    const tp = useProjectTranslation();
    const navigate = useNavigate();
    const classes = useStyles();

    const uniqueId = project?.uniqueId;

    const dateRegEx = /(^$)|(\d{2}.\d{2}.\d{4}|\d{4})/g;

    const ValidationSchema = Yup.object().shape({
        id: Yup.string().test('ID må være unik', (value) => {
            return new Promise((resolve, reject) => {
                idExists(collectionId, value)
                    .then(result => {
                        // NOTE: if in edit-mode, uniqueId is present,
                        // then resolve without taking the present ID into consideration.
                        if (result && !uniqueId) {
                            resolve();
                        } else {
                            reject();
                        }
                    });
            });
        }).required(),
        name: Yup.string().required(),
        description: Yup.string(),
        type: Yup.object().required(),
        startedAt: Yup.string()
            .transform(arg => {
                return arg === null ? '' : arg;
            }).matches(dateRegEx, "dd.mm.yyyy | yyyy"),
        endedAt: Yup.string()
            .transform(arg => {
                return arg === null ? '' : arg;
            })
            .matches(dateRegEx, "dd.mm.yyyy | yyyy"),
        active: Yup.bool(),
        contributors: Yup.array(),
        responsible: Yup.array(),
        relatedProjects: Yup.array(),
        remarks: Yup.string(),
        extReferences: Yup.string()
    });

    const handleCancel = () => {
        if (!uniqueId) {
            navigate('/all-projects/');
        } else {
            navigate(`/project/${project.id}`);
        }
    };

    const onSubmit = (values) => {
        save(collectionId, uniqueId, values, []);
    };

    /**
     * Gets the name of the selected museum, from the selected collection ID.
     * @param collectionId
     * @returns {*|string}
     */
    const getMuseumName = (collectionId) => {
        const museumsAndCollections = myMuseums.map((m) => {
            return {
                ...m,
                collectionId: museumCollections.find((mc) => mc.museumId === m.id)
                    ?.collectionId,
            };
        });
        const museum = museumsAndCollections?.find(
            (m) => m.collectionId === collectionId
        );
        return museum ? museum.name : "";
    };

    /**
     * Hook called once the data is saved, redirecting to the saved project's page.
     */
    useDeepCompareEffect(() => {
        const state = saveState();

        if (state.error && !state.pending) {
            clientLog('error', 'Failed to create the project');
        } else if (state.newFolder?.uniqueId && !state.error && !state.pending && state.success) {
            const projectId = state?.newFolder.id;
            if (projectId) {
                navigate(`/project/${projectId}`);
            }
        }
    }, [saveState, navigate]);

    /**
     * Hook used to get the Museum ID for the project's museum, based on the project's collection ID,
     * when editing a project.
     */
    useEffect(() => {
        if (!uniqueId || !project) {
            return;
        }

        const museumId = getMuseumIdFromCollectionId({
            museums: myMuseums,
            museumCollections: museumCollections,
            collectionId: project.collectionId
        });

        projectDispatch({
            type: PROJECT_SET_COLLECTION_ID,
            collectionId: collectionId,
            museumId: museumId
        });
    }, [collectionId, myMuseums, museumCollections, project, projectDispatch, uniqueId]);

    return (
        <Fade in={true} timeout={800}>
            <Paper sx={{
                marginTop: '20px',
                padding: '48px',
                width: '100%',
                height: '985px',
                marginLeft: '16px',
                marginRight: '16px'
            }}>
                <Typography variant={"h4"}
                            gutterBottom={true}>
                    {
                        uniqueId
                            ? tp('headingEditProject', 'Redigere prosjekt')
                            : tp('headingCreateProject', 'Opprette prosjekt')
                    }
                </Typography>
                <Box sx={{maxWidth: '600px'}}>
                    {
                        collectionId &&
                        <Formik
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={ValidationSchema}
                            enableReinitialize={true}
                        >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Box className={classes.box}>
                                    <TextField id="museum-named"
                                               label={tp('lblMuseum', 'Museum')}
                                               variant="standard"
                                               value={getMuseumName(collectionId)}
                                               disabled/>
                                </Box>
                                <Box className={classes.box}>
                                    <FormikSelect
                                        formikKey={"type"}
                                        label={tp('lblProjectType', 'Prosjekttype')}
                                        sx={{width: '266px'}}
                                        variant={"standard"}
                                    >
                                        {ProjectTypes().get()
                                            .filter(p => p.id !== initialValues.type.id)
                                            .map(p => {
                                                return <MenuItem value={p} key={p.id}>{p.name}</MenuItem>;
                                            })}
                                        <MenuItem value={initialValues.type}
                                                  key={'test'}>{initialValues.type.name}</MenuItem>
                                    </FormikSelect>
                                </Box>

                                <Stack direction={"row"} spacing={3} className={classes.box}>
                                    <FormikTextField
                                        formikKey={"id"}
                                        label={tp('lblId', 'ID')}
                                        helperText={tp('helperLblId', 'ID må være unik - ikke benyttet tidligere.')}
                                        disabled={false}
                                        variant={"standard"}
                                    />

                                    <FormikTextField
                                        formikKey={"name"}
                                        label={tp('lblNameTitle', 'Navn/Tittel')}
                                        helperText={""}
                                        disabled={false}
                                        variant={"standard"}
                                    />
                                </Stack>

                                <Stack direction={"row"} spacing={3} className={classes.box}>
                                    <FormikDateField formikKey={'startedAt'}
                                                     label={tp('lblProjectStartedAt', 'Prosjekt påbegynt')}
                                                     disabled={false}
                                                     helperText={"dd.mm.yyyy | yyyy"}
                                                     placeholder={"dd.mm.yyyy | yyyy"}
                                                     variant={"standard"}
                                                     defaultValue={initialValues.startedAt}
                                    />
                                    <FormikDateField formikKey={'endedAt'}
                                                     label={tp('lblProjectEndedAt', 'Prosjekt avsluttet')}
                                                     disabled={false}
                                                     helperText={"dd.mm.yyyy | yyyy"}
                                                     placeholder={"dd.mm.yyyy | yyyy"}
                                                     variant={"standard"}
                                                     defaultValue={initialValues.startedAt}
                                    />
                                </Stack>


                                <Box sx={{paddingTop: '16px', paddingBottom: '16px'}}>
                                    <FormikSelectDamsUsers formikKey={"contributors"}
                                                           museumId={museumId}
                                                           label={tp('lblContributors', 'Deltagende personer')}
                                                           placeholder={"søk..."}
                                    />
                                </Box>

                                <Box className={classes.box} sx={{paddingTop: '16px', paddingBottom: '16px'}}>
                                    <FormikSelectDamsUsers formikKey={'responsible'}
                                                           museumId={museumId}
                                                           label={tp('lblResponsible', 'Prosjektansvarlige')}
                                                           placeholder={"søk..."}
                                    />
                                </Box>

                                <Box className={classes.box}>
                                    <FormikTextField
                                        formikKey={"description"}
                                        label={tp('lblDescription', 'Beskrivelse')}
                                        helperText={""}
                                        disabled={false}
                                        variant={"standard"}
                                        multiline={true}
                                        fullWidth={true}
                                    />
                                </Box>

                                <Box className={classes.box}>
                                    <FormikTextField
                                        formikKey={"extReferences"}
                                        label={tp('lblExternalReference', 'Ekstern referanse')}
                                        helperText={""}
                                        disabled={false}
                                        variant={"standard"}
                                        multiline={true}
                                        fullWidth={true}
                                    />
                                </Box>

                                <Box className={classes.box}>
                                    <FormikSelectProjects museumId={museumId}
                                                          formikKey={"relatedProjects"}
                                                          label={tp('lblRelatedProjects', 'Relaterte prosjekter')}
                                                          placeholder={tp('placeholderSearch', 'søk...')}
                                                          projectType={"ProjectGeneral"}
                                                          projectId={project?.id}
                                    />
                                </Box>

                                <Box className={classes.box}>
                                    <FormikTextField
                                        formikKey={"remarks"}
                                        label={tp('lblComments', 'Kommentarer')}
                                        helperText={""}
                                        disabled={false}
                                        variant={"standard"}
                                        fullWidth={true}
                                        multiline
                                    />
                                </Box>


                                <Box className={classes.box}>
                                    <FormikSwitch formikKey={"active"}
                                                  label={tp('lblProjectState', 'Prosjektet er aktivt/åpent')}/>
                                </Box>

                                <Stack direction={"row"} justifyContent={'flex-end'}>
                                    <Button onClick={handleCancel}>Avbryt</Button>
                                    <FormikSubmitButton color={"primary"}
                                                        variant={"contained"}>
                                        {uniqueId ? tp('btnSave', 'Lagre') : tp('btnCreate', 'Opprett')}
                                    </FormikSubmitButton>
                                </Stack>
                            </LocalizationProvider>

                        </Formik>
                    }
                </Box>
            </Paper>
        </Fade>
    );
};