import React, {useState} from "react";
import {useField, useFormikContext} from "formik";
import {format, getDateInSevenDays} from "../app/dateFormat";
import {PopoverDatePicker} from "./PopoverDatePicker";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";

export const FormikEmailAcl = ({formikKey, className, label}) => {
    const [field] = useField(formikKey);
    const {setFieldValue} = useFormikContext();
    const [newEmail, setNewEmail] = useState("");

    const addHandler = () => {
        setFieldValue(`${field.name}[${field.value.length}]`, {
            email: newEmail,
            expiresAt: getDateInSevenDays(),
        }).then();

        setNewEmail("");
    };

    const removeHandler = (idx) => () => {
        const value = field.value.filter((_value, index) => index !== idx);
        setFieldValue(field.name, value).then();
    };

    const changeDate = (idx) => (newDate) => {
        setFieldValue(`${field.name}[${idx}]`, {
            ...field.value[idx],
            expiresAt: newDate,
        }).then();
    };

    return (
        <div className={className}>
            <Typography gutterBottom={true}>{label}</Typography>
            <TextField
                name={"Epost"}
                label={"Epost"}
                value={newEmail}
                variant={"outlined"}
                size={"small"}
                onChange={(event) => setNewEmail(event.target.value)}
            />
            <IconButton
                onClick={addHandler}
                disabled={Boolean(!newEmail.length)}
                color={"secondary"}
                size="large"
            >
                <AddCircleOutlineIcon/>
            </IconButton>
            {field.value.map((value, idx) => (
                <List key={crypto.randomUUID()}>
                    <ListItem>
                        <ListItemText
                            primary={value.email}
                            secondary={format(value.expiresAt, "P")}
                        />
                        <ListItemSecondaryAction>
                            <PopoverDatePicker
                                date={value.expiresAt}
                                changeHandler={changeDate(idx)}
                            />
                            <IconButton
                                edge={"end"}
                                aria-label={"delete"}
                                onClick={removeHandler(idx)}
                                size="large"
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            ))}
        </div>
    );
};
