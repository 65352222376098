import {useDmsFileMetadata} from "../dms/useDmsFileMetadata";
import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import {getDmsIdFromModel} from "../utility";

/**
 * Component used to display EXIF-data for a model.
 * @param model object  The model to display data for.
 * @param load  bool  True if data should be loaded.
 * @param smallScreen bool  Not currently in use!
 * @returns {JSX.Element|unknown[]}
 * @constructor
 */
export const ExifFileData = ({model, load, smallScreen = false}) => {
    const dmsId = getDmsIdFromModel(model);
    const fileMeta = useDmsFileMetadata({
        collectionId: model.collectionId,
        dmsId: dmsId,
        ready: load,
    });

    if (load && !smallScreen && !Object.keys(fileMeta).length) {
        return (
            <Box
                sx={{
                    display: "flex",
                    paddingTop: 8,
                    alignItems: "center",
                    justifyContent: "center",
                    height: "200px",
                }}
            >
                <LinearProgress color={"secondary"} sx={{width: "300px"}}/>
            </Box>
        );
    }

    return Object.keys(fileMeta).map((key) => {
        const k = crypto.randomUUID()
        return (
            <Grid
                container
                direction={"column"}
                key={k}
                justifyContent={"flex_start"}
                alignItems={"flex-start"}
            >
                <Grid item>
                    <Typography variant={"caption"} color={"GrayText"}>
                        {key}
                    </Typography>
                </Grid>
                <Grid item mb={2}>
                    <Typography variant={"caption"} gutterBottom={true}>
                        {fileMeta[key]}
                    </Typography>
                </Grid>
            </Grid>
        );
    });
};
