import {useField, useFormikContext} from "formik";
import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {useAuthsState} from "../auths/authsContext";
import {getUserEmails} from "./ekulturFetch";
import {useSearchTranslation} from "../search/SearchContext";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const PREFIX = "InputRecipientsSearch";

const classes = {
    root: `${PREFIX}-root`,
    icon: `${PREFIX}-icon`,
    marginBottom: `${PREFIX}-marginBottom`,
};

const Root = styled("div")(({theme}) => ({
    [`& .${classes.root}`]: {},

    [`&.${classes.marginBottom}`]: {
        marginBottom: theme.spacing(2),
    },
}));

const InputRecipientsSearch = ({formikKey, collectionId}) => {
    const [field] = useField(formikKey);
    const {setFieldValue} = useFormikContext();
    const [searching] = useState(false);
    const {museumCollections} = useAuthsState();
    const [emails, setEmails] = useState();

    const t = useSearchTranslation();

    const changeHandler = (_event, values, reason) => {
        if ("clear" === reason) {
            values = [];
        }
        setFieldValue("recipients", values);
    };

    useEffect(() => {
        const museumId = museumCollections.find(
            (c) => c.collectionId === collectionId
        ).museumId;
        getUserEmails(museumId).then((mails) => {
            setEmails(mails);
        });
    }, [collectionId, museumCollections]);

    return (
        <Root className={classes.marginBottom}>
            <Autocomplete
                id="relatedObjects"
                multiple
                loading={Boolean(searching)}
                loadingText={t("txtSearching", "Søker...")}
                getOptionLabel={(option) => option.email}
                options={emails || []}
                noOptionsText={t("txtNoRecipients", "Ingen mottagere")}
                autoComplete={true}
                value={field.value}
                onChange={changeHandler}
                renderInput={(params) => (
                    <TextField {...params} label={t("txtRecipients", "Mottagere")}/>
                )}
                renderOption={(props, option) => {
                    const key = `grid-${option.email}-${Math.random()}`;
                    return (
                        <Grid
                            {...props}
                            container
                            alignItems={"center"}
                            alignContent={"center"}
                            key={key}
                        >
                            <Grid item>
                                <AccountCircleIcon
                                    sx={{marginRight: 1}}
                                    color={"secondary"}
                                />
                            </Grid>
                            <Grid item xs>
                                <Typography variant={"body2"} sx={{paddingBottom: 0.5}}>
                                    {option.name} &lt;{option.email}&gt;
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                }}
            />
        </Root>
    );
};

InputRecipientsSearch.propTypes = {
    "formikKey": PropTypes.string.isRequired,
    "collectionId": PropTypes.number.isRequired
};

export {InputRecipientsSearch};