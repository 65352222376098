import React, {useEffect} from "react";
import {useSearchResultDetailsTranslation} from "../search-result-details/SearchResultDetailsContext";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CloseIcon from "@mui/icons-material/Close";

export const NavigationToolbar = ({
                                      numPages,
                                      currentPage,
                                      callback,
                                      closeCallback,
                                  }) => {
    const t = useSearchResultDetailsTranslation();

    const viewPage = (direction) => {
        let pageNo = direction === "next" ? currentPage + 1 : currentPage - 1;
        if (pageNo > numPages) {
            pageNo = currentPage;
        } else if (pageNo < 1) {
            pageNo = 1;
        }
        callback(pageNo);
    };

    const getPreviousPageButton = () => {
        return (
            <Grid item md={5} xs={4}>
                <IconButton
                    aria-label={t("previousPage", "Forrige side")}
                    sx={{
                        float: "right",
                        color: "white",
                    }}
                    onClick={() => viewPage("previous")}
                    disabled={currentPage === 1}
                    size="large"
                >
                    <NavigateBeforeIcon/>
                </IconButton>
            </Grid>
        );
    };

    const getNextPageButton = () => {
        return (
            <IconButton
                aria-label={t("nextPage", "Neste side")}
                sx={{
                    float: "left",
                    color: "white",
                }}
                onClick={() => viewPage("next")}
                disabled={currentPage === numPages}
                size="large"
            >
                <NavigateNextIcon/>
            </IconButton>
        );
    };

    const getCloseButton = () => {
        return (
            <IconButton
                aria-label={t("btnClose", "Lukk")}
                sx={{
                    float: "right",
                    position: "absolute",
                    top: "16px",
                    right: "16px",
                    color: "white",
                }}
                onClick={closeCallback}
                size="large"
            >
                <CloseIcon/>
            </IconButton>
        );
    };

    const getPageIndicator = () => {
        return (
            <Grid
                item
                md={2}
                xs={4}
                sx={{color: "white", textAlign: "center", marginTop: "8px"}}
            >
        <span>
            {currentPage} / {numPages}
        </span>
            </Grid>
        );
    };


    /**
     * Handle used to support navigating back/forth with the left- and right arrow keys.
     * @param event
     */
    const keyDownHandler = event => {
        if (event.key === 'ArrowLeft') {
            viewPage('previous');
        }

        if (event.key === 'ArrowRight') {
            viewPage('next');
        }
    }

    /**
     * Hook adding the event listener used to listen to keystrokes on the left- and right arrow keys.
     */
    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler, false);
        return () => {
            document.removeEventListener('keydown', keyDownHandler, false);
        }
    });

    return (
        <Grid container spacing={1} alignContent={"center"}>
            {getPreviousPageButton()}
            {getPageIndicator()}
            <Grid item md={5} xs={4}>
                {getNextPageButton()}
                {getCloseButton()}
            </Grid>
        </Grid>
    );
};
