import React, {useRef} from "react";
import {SHOW_ADD_MORE_FILES, UPDATE_FILES, useDocumentDispatch, useDocumentState,} from "../documents/documentContext";
import {FileCard} from "./FileCard";
import {UploadMoreFiles} from "./UploadMoreFiles";
import {useDmsState} from "../dms/dmsContext";
import {useNewFilesTranslation} from "./NewFilesTranslationContext";
import {useUploadFiles} from "./useUploadFiles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {damsFetch} from "../app/damsFetch";
import {clientLog} from "../clientLog";
import useDeepCompareEffect from "use-deep-compare-effect";
import {getObjectStatus} from "../getObjectStatus";

export const AddedFilesGrid = ({hide = false}) => {
    const t = useNewFilesTranslation();
    const imageDispatch = useDocumentDispatch();

    const {files, showAddMoreFiles, formData, failed} = useDocumentState();
    const {progressByFile} = useDmsState();

    const gridRef = useRef();

    const docDispatch = useDocumentDispatch();

    useUploadFiles(formData.collectionId);

    /**
     * Method used to get the thumbnail URLs for the uploaded files.
     * @param collectionId
     * @param dmsIds
     * @returns {Promise<void>}
     */
    const getImageUrls = async (collectionId, dmsIds) => {
        const params = {
            collectionId: collectionId,
            dmsIds: dmsIds
        }
        const url = '/v2/dms/image-urls/';
        const imageUrls = await damsFetch(url, {
            method: "POST",
            body: JSON.stringify(params),
        });

        const withThumbnail = [...files].map(f => {
            return {
                ...f,
                thumbnailUrl: `${imageUrls[f.dmsId]}&dimension=250x250&mediaType=image/webp`
            }
        });
        docDispatch({type: UPDATE_FILES, files: withThumbnail});
    };

    /**
     * Callback run when a conversion status request is complete.
     * Used to further initiate thumbnail URL downloads.
     * @param data
     * @param collectionId
     */
    const conversionStatusCallback = (data, collectionId) => {
        getImageUrls(collectionId, data.map(f => (f.identifier)))
            .then();
    };

    /**
     * Hook used to initiate the phase that fetches the conversion-status for each uploaded file,
     * and when conversion is done, gets the thumbnail URLs.
     */
    useDeepCompareEffect(() => {
        if (files.filter(f => f.thumbnailUrl).length > 0) {
            return;
        }


        /**
         * Method used to get the conversion status for each of the uploaded files.
         * @param dmsIds
         */
        const getConversionStatus = (dmsIds) => {
            const collectionId = formData.collectionId;
            getObjectStatus(
                collectionId,
                dmsIds,
                (data) => conversionStatusCallback(data, collectionId),
                (error) => {
                    clientLog('error', error, 'AddedFilesGrid');
                });
        };

        const damsFiles =
            files.filter(f => f.dmsId
                && (f.mimeType.indexOf('image') > -1
                    || f.mimeType.indexOf('video') > -1
                    || f.mimeType.indexOf('pdf') > -1));

        if (files.length > 0 && (damsFiles.length + failed.length === files.length)) {
            const dmsIds = files.map(f => f.dmsId);
            getConversionStatus(dmsIds);
        }
    }, [files, failed]);

    const openAddMoreFiles = () => {
        imageDispatch({
            type: SHOW_ADD_MORE_FILES,
        });
    };

    const getUploadProgressText = () => {
        const numberOfUploaded = Object.values(progressByFile).filter(
            (value) => 100 === value
        ).length;
        const numberTotal = files.length;
        return t("uploadProgressText", "{{uploaded}} av {{total}} er lastet opp", {
            uploaded: numberOfUploaded,
            total: numberTotal,
        });
    };

    return (
        <Box
            sx={{
                padding: '16px',
                display: hide ? "none" : "inherit",
            }}
        >
            <Toolbar>
                <Box flexGrow={1}/>
                <Typography>{getUploadProgressText()}</Typography>
                <Box flexGrow={0.1}/>
                <Button
                    startIcon={<CloudUploadIcon color={"disabled"}/>}
                    onClick={openAddMoreFiles}
                    aria-label={"upload-more"}
                >
                    {t("uploadMore", "Last opp flere")}
                </Button>
            </Toolbar>
            <UploadMoreFiles open={showAddMoreFiles}/>
            <Grid
                container
                direction={"row"}
                spacing={1}
                sx={{
                    paddingTop: 1,
                    paddingBottom: 1,
                }}
                ref={gridRef}
            >
                {files
                    .sort((a, b) => a.title.localeCompare(b.title))
                    .map((file) => (
                        <Grid item key={file.title}>
                            <FileCard
                                collectionId={formData.collectionId}
                                file={file}
                                progress={progressByFile[file.title] || 0}
                            />
                        </Grid>
                    ))}
            </Grid>
        </Box>
    );
};
