import decamelizeKeysDeep from "decamelize-keys-deep";
import {damsSearch} from "../app/damsFetch";
import {inputWithCollectionIds} from "../metadata/inputMapper";

/**
 * Returns the search parameter object, used when searching for persons.
 * @param params
 * @param museums
 * @returns {module:url.URLSearchParams}
 */
export const getSearchParams = (params, museums) => {
    return new URLSearchParams(
        decamelizeKeysDeep({
            q: `${params
                .split(" ")
                .map((word) => `_title_:*${word}`)
                .join(" AND ")}`,
            fl: "title,id",
            sort: "title asc",
            expand: true,
            documentType: `("person")`,
            museums: museums.join(","),
        })
    );
};

/**
 * Method used to fetch the persons saved in the DAMS database.
 * @param params object   Object containing the search parameters.
 * @param museums Array List of museum IDs to fetch data for.
 * @returns {Promise<unknown[]>}
 */
export const fetchPersonsFromDamsDb = async (params, museums) => {
    const searchParams = getSearchParams(params, museums);
    return damsSearch(searchParams).then((json) => {
            return inputWithCollectionIds(json['models']);
        }
    );
};