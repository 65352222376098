import { ObjectViewMetaSection } from "./ObjectViewMetaSection";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  portraitBox: {
    backgroundColor: "white",
    maxHeight: "70vh",
    paddingTop: 1,
  },
  landscapeBox: {
    backgroundColor: "white",
    height: "100vh",
    minHeight: "100vh",
    width: "70%",
    minWidth: "70%",
    paddingTop: 1,
  },
}));

/**
 * Wrapper used to adapt the object view meta section to a small or large screen.
 * @param smallScreen   bool    True, if displayed on a small screen
 * @param columnDefs    Array   Array of column defs [left, right]
 * @param dialogRef   ref Reference to the meta section container dialog
 * @param t object  Language object
 * @returns {JSX.Element}
 * @constructor
 */
export const ObjectViewMetaSectionWrapper = ({
  smallScreen,
  columnDefs,
  dialogRef,
  t,
}) => {
  const classes = useStyles();
  const isPortrait = useMediaQuery("(orientation:portrait)");
  return (
    <>
      {!smallScreen && (
        <Grid
          item
          md={columnDefs[1]}
          sx={{
            backgroundColor: "white",
            minHeight: "100%",
            zIndex: 20,
          }}
        >
          <ObjectViewMetaSection
            dialogRef={dialogRef}
            t={t}
            smallScreen={smallScreen}
          />
        </Grid>
      )}
      {smallScreen && (
        <Box
          className={isPortrait ? classes.portraitBox : classes.landscapeBox}
        >
          <ObjectViewMetaSection
            dialogRef={dialogRef}
            t={t}
            smallScreen={smallScreen}
          />
        </Box>
      )}
    </>
  );
};
