import React from "react";
import {WithProgressCircle} from "../app/WithProgressCircle";
import {getTableColumns} from "./myFoldersTableColumns";
import {styled} from "@mui/material/styles";
import {SET_SEARCHING, SET_SORT, useFoldersDispatch, useFoldersState, useFoldersTranslation,} from "./FoldersContext";
import {ResultTableColumnSelector} from "../search/ResultTableColumnSelector";
import {ResultTableHead} from "../search/ResultTableHead";
import {useMyMuseums} from "../museum/useMyMuseums";
import {useAuthsState} from "../auths/authsContext";
import {SELECT, SET_SELECTED, UNSELECT} from "../search/SearchContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

import Toolbar from "@mui/material/Toolbar";
import {useNavigate} from "react-router-dom";
import {SET_AVAILABLE_COLUMNS, useResultTableDispatch, useResultTableState} from "../search/ResultTableContext";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import {decamelize} from "../app/decamelize";
import {FolderSearchSettings} from "../search/FolderSearchSettings";
import useDeepCompareEffect from "use-deep-compare-effect";

const PREFIX = "FoldersTable";

const classes = {
    table: `${PREFIX}-table`,
};

const Root = styled("div")(() => ({
    [`& .${classes.table}`]: {
        //minWidth: 750,
    },
}));

export const FoldersTable = () => {
    const folderSearchSettings = FolderSearchSettings('dams.folderListSearchResults.config');
    const {sort, results, searching, selected} = useFoldersState();
    const searchDispatch = useFoldersDispatch();
    const [orderBy, order] = sort.split(" ");
    const tableColumns = getTableColumns();
    const t = useFoldersTranslation();
    const navigate = useNavigate();
    const myMuseums = useMyMuseums({});
    const {museumCollections} = useAuthsState();

    const resultTableDispatch = useResultTableDispatch();
    const {columnsToRender} = useResultTableState();

    const contextKey = 'folder';

    /**
     * Hook used to get, and set the default available columns.
     */
    useDeepCompareEffect(() => {
        if (!tableColumns) {
            return;
        }

        function checkActive(columns) {
            return columns.map(c => {
                let render = false;
                if (!c.checked && c.defaultHidden === false) {
                    render = true;
                }
                if (c.checked) {
                    render = c.checked;
                }
                return {...c, checked: render};
            });
        }

        const savedCols = localStorage.getItem('dams.' + contextKey + '.columns');
        let filteredTableColumns = [...tableColumns];

        if (savedCols) {
            filteredTableColumns = filteredTableColumns.map(tc => {
                return {
                    ...tc,
                    checked: savedCols.includes(tc.id),
                    defaultHidden: true
                }
            });
        }

        resultTableDispatch({
            type: SET_AVAILABLE_COLUMNS,
            availableColumns: checkActive(filteredTableColumns),
            contextKey: 'folder',
        });
    }, [resultTableDispatch]);


    const handleRequestSort = (_event, property) => {
        const isAsc = orderBy === decamelize(property) && order === "asc";
        const sortOrder = isAsc ? "desc" : "asc";
        searchDispatch({
            type: SET_SORT,
            sort: `${property} ${sortOrder}`,
        });
        folderSearchSettings.setSortColumnAndOrder(property, sortOrder);
        searchDispatch({
            type: SET_SEARCHING
        });
    };


    const setSelected = (selection) => {
        searchDispatch({
            type: SET_SELECTED,
            selected: selection,
        });
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            setSelected(results.models);
            return;
        }
        setSelected([]);
    };

    const handleCheck = (isItemSelected, model) => {
        if (isItemSelected) {
            searchDispatch({
                type: UNSELECT,
                model: model,
            });
        } else {
            searchDispatch({
                type: SELECT,
                model: model,
            });
        }
    };


    const isSelected = (uniqueId) =>
        Boolean(selected.find((model) => uniqueId === model.uniqueId));

    const handleRowClick = (model) => {
        navigate(`/folders/${model.id}/`);
    };

    const setCellValue = (cell, row, transObj) => {
        const v = cell.getValue(row, transObj);
        if (cell.id === "museum") {
            return getMuseumName(v);
        } else {
            return v;
        }
    };

    const getMuseumName = (collectionId) => {
        const museumsAndCollections = myMuseums.map((m) => {
            return {
                ...m,
                collectionId: museumCollections.find((mc) => mc.museumId === m.id)
                    ?.collectionId,
            };
        });
        const museum = museumsAndCollections?.find(
            (m) => m.collectionId === collectionId
        );
        return museum ? museum.name : "";
    };

    return (
        <Root sx={{overflow: 'hidden', display: 'flex', flexDirection: 'column',}} id="FoldersTable-root">
            <Toolbar sx={{borderBottom: '1px solid #E0E0E0'}}>
                <ResultTableColumnSelector
                    t={t}
                />
            </Toolbar>
            <WithProgressCircle searching={searching} sx={{flexGrow: 1}}>
            </WithProgressCircle>
            <TableContainer sx={{flexGrow: 1}}>
                <Table
                    stickyHeader
                    className={classes.table}
                    aria-labelledby="search-results-title"
                    size={"medium"}
                    aria-label="search-results-table"
                >
                    <ResultTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={results.models.length}
                        t={t}
                    />
                    <TableBody>
                        {results.models.map((row) => {
                            const isItemSelected = isSelected(row.uniqueId);
                            const cellCheckboxKey = `cell-checkbox-${row.uniqueId}`;
                            const checkboxKey = `checkbox-${row.uniqueId}`;
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.uniqueId}
                                    selected={isItemSelected}
                                    sx={{cursor: "pointer"}}
                                >
                                    <TableCell
                                        padding="checkbox"
                                        onClick={() => handleCheck(isItemSelected, row)}
                                        key={cellCheckboxKey}
                                    >
                                        <Checkbox checked={isItemSelected} key={checkboxKey}/>
                                    </TableCell>
                                    {columnsToRender.map((cell) => (
                                        <TableCell
                                            key={cell.id}
                                            {...cell.cellProps}
                                            onClick={() => handleRowClick(row)}
                                        >
                                            {setCellValue(cell, row, t)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            );
                        })}

                    </TableBody>
                </Table>
            </TableContainer>

        </Root>
    );
};
