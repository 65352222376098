import {AvailableFolders} from "./folders/AvailableFolders";
import {NewFilesTranslationProvider} from "../files/NewFilesTranslationContext";
import {FoldersProvider} from "../folder/FoldersContext";
import React, {useEffect, useRef, useState} from "react";
import Grid from "@mui/material/Grid";
import {
    CLEAR_EDITED_FLAG,
    CLEAR_SELECTED,
    useFileUploadDispatch,
    useFileUploadState,
    useFileUploadTranslation
} from "./fileUploadContext";
import {SelectedObjectsHeader} from "./SelectedObjectsHeader";
import Typography from "@mui/material/Typography";
import {Dialog, Slide, Toolbar, useMediaQuery} from "@mui/material";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {CheckableFileGridItem} from "./folders/CheckableFileGridItem";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {DraggableFileGridItem} from "./folders/DraggableFileGridItem";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const StepAddToFolder = () => {
    const t = useFileUploadTranslation();
    const {selected, uploadedFiles} = useFileUploadState();
    const fileUploadDispatch = useFileUploadDispatch();

    const [openDialog, setOpenDialog] = useState(false);

    const smallScreen = useMediaQuery("(max-width: 1919px)");

    const scrollableGridRef = useRef();

    const getHelpText = () => {
        let style = {
            maxWidth: '400px',
            width: '400px',
            display: 'block',
            marginBottom: '16px'
        };

        let text = t('stepAddtoFolderMarkFiles', 'Klikk på en eller flere filer for å markere dem. Dra deretter filen(e) over i ønsket mappe. Alt. dra en og en fil direkte over i ønsket mappe.');

        if (smallScreen) {
            text = t('stepAddToFolderMarkFilesCompact', 'Marker en eller flere filer, klikk deretter på "Velg mappe", for å legge dem i en mappe.');
            style = {
                ...style,
                maxWidth: '100%',
                width: '100%'
            };
        }

        return <Typography variant={"caption"}
                           sx={style}>{text}</Typography>;
    };

    /**
     * Opens the dialog containing the list of available folders.
     */
    const handleChooseFolderButtonClick = () => {
        setOpenDialog(true);
    };

    /**
     * Closes the edit dialog, displayed on smaller screens.
     */
    const closeDialog = () => {
        if (smallScreen) {
            setOpenDialog(false);
        }
    };

    /**
     * Handler triggered when the edit-dialog (small screen) is closed.
     */
    const handleDialogClose = () => {
        closeDialog();
    };

    const getFolderListDialog = () => {
        return <Dialog
            fullScreen
            open={openDialog}
            onClose={handleDialogClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{position: 'relative'}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleDialogClose}
                        aria-label="lukk"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                        {t('stepAddToFolder', 'Velg mappe -{{numSelected}} filer valgt', {numSelected: selected.length})}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box sx={{padding: '16px'}}>
                <FoldersProvider>
                    <AvailableFolders/>
                </FoldersProvider>
            </Box>
        </Dialog>;
    };

    /**
     * Hook used to clear the "edited" flag, as this is irrelevant in this context.
     */
    useEffect(() => {
        fileUploadDispatch({
            type: CLEAR_EDITED_FLAG
        });
    }, []);

    /**
     * Hook used to clear selected, if any.
     */
    useEffect(() => {
        fileUploadDispatch({
            type: CLEAR_SELECTED
        });
    }, []);

    const componentContainerRef = useRef();

    if (!smallScreen) {
        return <NewFilesTranslationProvider>

            <Stack direction={"row"}
                   ref={componentContainerRef}
                   sx={{height: '100%', minWidth: '100%', width: '100%'}}>

                <Stack direction={"column"} sx={{maxWidth: '60%'}}>

                    <Box>
                        <SelectedObjectsHeader/>
                        {getHelpText()}
                    </Box>

                    <Grid ref={scrollableGridRef}
                          container
                          direction={"row"}
                          sx={{
                              overflowY: 'auto',
                              alignContent: 'flex-start',
                              minWidth: '60vw'
                          }}>
                        {uploadedFiles.map((f) => (
                            <Grid item key={`grid-item-${f.uniqueId}`}>
                                <DraggableFileGridItem model={f}/>
                            </Grid>
                        ))}
                    </Grid> {/* scrollable grid */}
                </Stack> {/* 1st column */}

                <Stack direction={"row"} item sx={{
                    width: '40%',
                    paddingLeft: '24px'
                }}>
                    <FoldersProvider>
                        <AvailableFolders/>
                    </FoldersProvider>
                </Stack> {/* 2nd column */}

            </Stack> {/* container */}

        </NewFilesTranslationProvider>;
    } else {
        return <NewFilesTranslationProvider>
            <Stack direction={"column"}>
                <SelectedObjectsHeader>
                    <Button
                        size={"small"}
                        color={"secondary"}
                        variant={"contained"}
                        onClick={handleChooseFolderButtonClick}
                        disabled={selected.length === 0}
                    >
                        {t('stepAddToFolder', 'Velg mappe')}
                    </Button>
                </SelectedObjectsHeader>
                {getHelpText()}
                <Stack direction={"row"}>
                    <Grid
                        container
                        direction={"row"}
                        alignContent={"flex-start"}
                        justifyContent={"flex-start"}
                        sx={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            height: '50vh',
                            maxHeight: '50vh'
                        }}
                    >
                        <Grid item md={8}>
                            <Grid container spacing={1} direction={"column"}>
                                {uploadedFiles.map((f) => (
                                    <Grid item key={f.uniqueId}>
                                        <CheckableFileGridItem model={f}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
            {getFolderListDialog()}
        </NewFilesTranslationProvider>
    }
};