import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useMenuTranslation } from "./menuTranslationContext";
import { useAuthsState } from "../auths/authsContext";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import ImageIcon from "@mui/icons-material/Image";
import MovieIcon from "@mui/icons-material/Movie";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import CloseIcon from "@mui/icons-material/Close";
import { useAppState } from "../app/AppContext";

const PREFIX = "FloatingActionButton";

const classes = {
    speedDial: `${PREFIX}-speedDial`,
};

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
    [`&.${classes.speedDial}`]: {
        position: "absolute",
        bottom: theme.spacing(6),
        right: theme.spacing(6),
        [theme.breakpoints.up("xl")]: {
            bottom: theme.spacing(8),
            right: theme.spacing(8),
        },
    },
}));

export const FloatingActionButton = () => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { userIsAuthenticated, userData } = useAuthsState();
    const { museumUserOrAdmin } = useAppState();
    const t = useMenuTranslation();

    const actions = [
        {
            icon: <ImageIcon />,
            id: "stillImage",
            name: t('actionImage', 'Bilde'),
            path: "/new-images/",
            ariaLabel: t('actionHelpImage', 'lag nytt bilde'),
        },
        {
            icon: <MovieIcon />,
            id: "movingImage",
            name: t('actionVideo', 'Film'),
            path: "/new-videos/",
            ariaLabel: t('actionHelpVideo', 'lag ny film'),
        },
        {
            icon: <VolumeUpOutlinedIcon />,
            id: "sound",
            name: t('actionAudio', 'Lyd'),
            path: "/new-sounds/",
            ariaLabel: t('actionHelpAudio', 'lag ny lyd'),
        },
        {
            icon: <DescriptionIcon />,
            id: "misc",
            name: t('actionMisc', 'Dokument'),
            path: "/new-documents/",
            ariaLabel: t('actionHelpMisc', 'lag nytt dokument'),
        },
    ];

    const handleOpen = (_event, reason) => {
        if ("toggle" === reason) {
            setOpen(true);
        }
    };

    const handleClose = (path) => (_event, reason) => {
        if ("mouseLeave" !== reason) {
            setOpen(false);
            if (path) {
                navigate(path);
            }
        }
    };

    const isHidden = () => {
        if (userData['appAccess']?.museums?.length === 0 && !userData['contributor']) {
            return true;
        } else if (!userIsAuthenticated) {
            return true;
        } else {
            // Hide, based on current path
            if (location.pathname.includes("/new")
                || location.pathname.includes("/all-projects")
                || location.pathname.includes("project")) {
                return true;
            }
        }
    };

    return (museumUserOrAdmin &&
        <StyledSpeedDial
            ariaLabel="Toggle speed dial"
            className={classes.speedDial}
            icon={<SpeedDialIcon openIcon={<CloseIcon />} />}
            FabProps={{
                color: "secondary",
            }}
            onClose={handleClose()}
            onOpen={handleOpen}
            open={open}
            hidden={isHidden()}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.id}
                    aria-label={action.ariaLabel}
                    icon={action.icon}
                    tooltipTitle={t(action.id, action.name)}
                    tooltipOpen={"ontouchstart" in window}
                    onClick={handleClose(action.path)}
                />
            ))}
        </StyledSpeedDial>
    );
};
